import {
  GetInTouchSection,
  GetInTouchSectionProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  ContactFormDataInput,
  FormQuery,
  useSendContactFormMutation,
} from '@/api';

export const Form = ({ data }: { data: FormQuery }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendForm] = useSendContactFormMutation({
    onCompleted() {
      setSuccess(true);
    },
  });

  const onSubmit: SubmitHandler<ContactFormDataInput> = async (data) => {
    setLoading(true);
    await sendForm({ variables: { data } });
  };

  const formData = data?.getInTouch?.data?.attributes;

  const formProps: GetInTouchSectionProps = {
    anchor: formData?.anchor || undefined,
    title: formData?.title || '',
    descriptionFirstLine: formData?.first_line || '',
    descriptionSecondLine: formData?.second_line || '',
    onSubmit: onSubmit,
    loading: loading,
    success: success,
    setLoading: () => setLoading(!loading),
    setSuccess: () => setSuccess(!success),
    useCase: 'technology' as useCaseType,
  };

  return <GetInTouchSection {...formProps} />;
};

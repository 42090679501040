import {
  HeroSection,
  HeroSectionProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import { HeroVideoSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeroVideoSectionProps {
  hero?: HeroVideoSectionFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export default function HeroVideoSection({
  hero,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroVideoSectionProps) {
  const heroProps: HeroSectionProps = {
    useCase: 'technology' as useCaseType,
    enabledLazyLoading: true,
    titleMaxWidth,
    subtitleMaxWidth,
    title: hero?.title || '',
    subtitle: hero?.subtitle || '',
    button: hero?.button?.label
      ? {
          path: hero?.button?.path || '',
          label: hero?.button?.label || '',
          useCase: 'technology',
        }
      : undefined,
    heroVideo: {
      enabledLazyLoading: true,
      pauseVideo: undefined,
      video: {
        posterAlt: hero?.heroVideo?.poster_alt || '',
        posterSrc: getCdlPublicId(
          hero?.heroVideo?.poster?.data?.attributes?.url || '',
        ),
        videoSrc: getCdlPublicId(
          hero?.heroVideo?.video?.data?.attributes?.url || '',
        ),
        blurHash:
          hero?.heroVideo?.poster?.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
      },
    },
    heroVideoPreview: hero?.showPreviewVideo
      ? {
          enabledLazyLoading: true,
          video: {
            alt: hero?.previewVideo?.poster_alt || '',
            imgPosterSrc:
              getCdlPublicId(
                hero?.previewVideo?.poster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            imgPosterSrcMobile:
              getCdlPublicId(
                hero?.previewVideo?.mobilePoster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            fullVideo: {
              enabledLazyLoading: true,
              posterAlt: hero?.previewVideo?.poster_alt || '',
              posterSrc:
                getCdlPublicId(
                  hero?.previewVideo?.poster?.data?.attributes?.url,
                ) || 'rimac/ui-lib/sample',
              videoSrc:
                getCdlPublicId(
                  hero?.previewVideo?.video?.data?.attributes?.url,
                ) || ' ',
              blurHash:
                hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
                'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
            },
            blurHash:
              hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
              'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
            videoDuration: hero?.previewVideoLength || '',
          },
          onClick: () => {},
        }
      : undefined,
  };

  return <HeroSection {...heroProps} />;
}
